const regionColor = {
  ausasi: '#a13e0e',
  cei: '#ec6625',
  aml: '#f18c5b',
  amnord: '#fad8c7',
  moy: '#bdf3ff',
  afriq: '#5be1ff',
  asie: '#008aa9',
  asiepac: '#008aa9',
  europe: '#004a5b'
}

const energyColor = {
  coal: '#b47836',
  gas: '#808080',
  oil: '#000000',
  biomass: '#6fc22f',
  solar: '#c7af2e',
  wind: '#00a2d2',
  geothermal_heat: '#887400',
  electricity: '#ffd360',
  oil_products: '#333333',
  solar_heat: '#c7af2e',
  heat: '#ec6625',
  renewables: '#174211',
  non_renewables: '#8d7979',
  others: '#8d7979',
  h2: '#D4E9F5'
}

const categoryColor = {

  total_energy: '#ec6625',
  crude_oil: energyColor.oil,
  oil_products: energyColor.oil_products,
  natural_gas: energyColor.gas,
  coal_lignite: energyColor.coal,
  power: energyColor.electricity,
  renewables: energyColor.renewables,
  emissions: '#004a5b'
}

function getColorForRegionCode (code) {
  if (code === null || code === undefined) {
    return null
  }
  // on force les minuscules pour éviter les erreurs
  code = code.toLowerCase()
  if (regionColor[code] !== undefined) {
    return regionColor[code]
  } else {
    return '#ffffff'
  }
}

function getColorForEnergyName (energy) {
  if (energy === null || energy === undefined) {
    return null
  }
  // on force les minuscules pour éviter les erreurs
  energy = energy.toLowerCase()
  if (energyColor[energy] !== undefined) {
    return energyColor[energy]
  } else {
    return '#ffffff'
  }
}

function getColorForCategoryName (category) {
  if (category === null || category === undefined) {
    return null
  }
  // on force les minuscules pour éviter les erreurs
  category = category.toLowerCase()
  if (categoryColor[category] !== undefined) {
    return categoryColor[category]
  } else {
    return '#ffffff'
  }
}

export { getColorForRegionCode, getColorForEnergyName, getColorForCategoryName }
