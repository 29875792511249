import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9867c9f0 = () => interopDefault(import('../pages/glossary.vue' /* webpackChunkName: "pages/glossary" */))
const _e245f35c = () => interopDefault(import('../pages/scenarioDescription.vue' /* webpackChunkName: "pages/scenarioDescription" */))
const _5cdec0d4 = () => interopDefault(import('../pages/moreSeries.vue' /* webpackChunkName: "pages/moreSeries" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2fa1ac72 = () => interopDefault(import('../pages/activate-account/_mailHash.vue' /* webpackChunkName: "pages/activate-account/_mailHash" */))
const _40e8f248 = () => interopDefault(import('../pages/change-password/_contactId/_token.vue' /* webpackChunkName: "pages/change-password/_contactId/_token" */))
const _d2874a18 = () => interopDefault(import('../pages/_page.vue' /* webpackChunkName: "pages/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/energy-glossary.html",
    component: _9867c9f0,
    name: "glossary___en"
  }, {
    path: "/energy-glossary.html",
    component: _9867c9f0,
    name: "glossary___ja"
  }, {
    path: "/energy-glossary.html",
    component: _9867c9f0,
    name: "glossary___ko"
  }, {
    path: "/energy-scenarios-description.html",
    component: _e245f35c,
    name: "scenarioDescription___en"
  }, {
    path: "/energy-scenarios-description.html",
    component: _e245f35c,
    name: "scenarioDescription___ja"
  }, {
    path: "/energy-scenarios-description.html",
    component: _e245f35c,
    name: "scenarioDescription___ko"
  }, {
    path: "/energy-scenarios-series-indicators.html",
    component: _5cdec0d4,
    name: "moreSeries___en"
  }, {
    path: "/energy-scenarios-series-indicators.html",
    component: _5cdec0d4,
    name: "moreSeries___ja"
  }, {
    path: "/energy-scenarios-series-indicators.html",
    component: _5cdec0d4,
    name: "moreSeries___ko"
  }, {
    path: "/register.html",
    component: _5fba350d,
    name: "register___en"
  }, {
    path: "/register.html",
    component: _5fba350d,
    name: "register___ja"
  }, {
    path: "/register.html",
    component: _5fba350d,
    name: "register___ko"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ja"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___ko"
  }, {
    path: "/activate-account/:mailHash?",
    component: _2fa1ac72,
    name: "activate-account-mailHash___en"
  }, {
    path: "/activate-account/:mailHash?",
    component: _2fa1ac72,
    name: "activate-account-mailHash___ja"
  }, {
    path: "/activate-account/:mailHash?",
    component: _2fa1ac72,
    name: "activate-account-mailHash___ko"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___en"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___ja"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___ko"
  }, {
    path: "/:page",
    component: _d2874a18,
    name: "page___en"
  }, {
    path: "/:page",
    component: _d2874a18,
    name: "page___ja"
  }, {
    path: "/:page",
    component: _d2874a18,
    name: "page___ko"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
