export default function ({ store, route, error }) {
  const categoryPattern = /^page/
  const moreSeriePattern = /^moreSeries/
  if (categoryPattern.test(route.name) || moreSeriePattern.test(route.name)) {
    store.dispatch('page/setPageContentForRoutePath', route.path)
    if (store.getters['page/pageContent'] === undefined) {
      return error({ statusCode: 404, message: 'not found' })
    }
  }
}
