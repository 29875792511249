let axios
let url

export default {
  setAxios ($axios) {
    axios = $axios
  },
  setUrl (paramUrl) {
    url = paramUrl
  },
  // zonesByNrdCode (codes) {
  //   const requestParams = {
  //     nrdCode: codes,
  //     pagination: false
  //   }
  //   return axios.get(url + '/zones', { params: requestParams })
  // },
  zones () {
    const requestParams = {
      services: {
        code: 'outlook'
      },
      pagination: false
    }
    return axios.get(url + '/zones', { params: requestParams })
  },
  units () {
    const requestParams = {
      services: {
        code: 'outlook'
      },
      pagination: false
    }
    return axios.get(url + '/units', { params: requestParams })
  }
}
