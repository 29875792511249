
import newApi from '../../api/news'
import NewsBlock from './NewsBlock'
export default {
  name: 'NewsLine',
  components: { NewsBlock },
  data () {
    return {
      news: []
    }
  },
  async fetch () {
    this.news = await newApi.getLastNews({ topic: '380' })
      .then(res =>
        res.data
      )
      .catch(reason =>
        []
      )
  },
  fetchOnServer: false
}
