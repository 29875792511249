import { htmlize } from '@/utils/StringUtils'

export const state = () => ({
  message: null
})

export const getters = {
  message: state => state.message,
  keyDataBlocks: (state, getters, rootState, rootGetters) => {
    const returnedArray = []
    const pages = rootGetters.pages
    const n = pages.length
    for (let i = 0; i < n; i++) {
      const page = pages[i]
      if (page.keys !== undefined) {
        const keyItem = page.keys.data
        if (keyItem.displayedOnHomepage === true) {
          const title = keyItem.title !== '' ? keyItem.title : page.category1
          const keyFigure = {
            id: page.id,
            url: '/' + page.url + '.html',
            title: htmlize(title),
            icon: page.icone,
            value: keyItem.number,
            text: htmlize(keyItem.text)
          }
          returnedArray.push(keyFigure)
        }
      }
    }
    return returnedArray
  }
}

export const actions = {
  setMessage ({ commit }, value) {
    commit('SET_MESSAGE', value)
  },
  clearMessage ({ commit }) {
    commit('SET_MESSAGE', null)
  }
}

export const mutations = {
  SET_MESSAGE (state, value) {
    state.message = value
  }
}
