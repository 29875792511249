import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: '952a0eb8c11e7ada6972a3153703fa0b',
  releaseStage: 'production',
  // enabledReleaseStages: ['production', 'staging'],
  plugins: [new BugsnagPluginVue(Vue)]
})
Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
