
import { htmlize } from '@/utils/StringUtils'

export default {
  name: 'FooterColumn',
  props: {
    content: {
      required: true,
      type: Object
    }
  },
  methods: {
    htmlize (text) {
      return htmlize(text)
    }
  }
}
