export const state = () => ({
  pageContent: null,
  pageData: [],
  currentYear: null,
  mapRegion: null,
  benchmarkRegions: []
})

export const getters = {
  pageContent: (state) => {
    return state.pageContent
  },
  pageType: (state) => {
    if (state.pageContent.icone === 'regional_data') {
      return 'country-dashboard'
    } else if (state.pageContent.icone === 'more') {
      return 'more-series'
    } else {
      return 'data'
    }
  },
  nbDecimal: (state) => {
    return state.pageContent.map.nbDecimale
  },
  // rankingOrder: (state) => {
  //   return state.pageContent.rankingOrder
  // },
  mapContent: (state) => {
    return state.pageContent.map
  },
  mapRegion: (state, getters, rootState, rootGetters) => {
    if (state.mapRegion === null) {
      return rootGetters.world
    } else {
      return state.mapRegion
    }
  },
  benchmarkRegions: (state) => {
    return state.benchmarkRegions
  }
}

export const actions = {
  setPageContentForRoutePath ({ commit, rootGetters }, path) {
    const data = rootGetters.pageByRoutePath(path)
    commit('SET_PAGE_CONTENT', data)
  },
  setMapRegion ({ commit }, data) {
    commit('SET_MAP_REGION', data)
  },
  clearMapRegion ({ commit }) {
    commit('SET_MAP_REGION', null)
  },
  clearPageContent ({ commit }) {
    commit('SET_PAGE_CONTENT', null)
  },
  setBenchmarkRegions ({ commit }, data) {
    commit('SET_BENCHMARK_REGIONS', data)
  },
  removeBenchmarkRegion ({ state, commit }, region) {
    const newBenchmarkRegions = state.benchmarkRegions.filter(function (benchmarkRegion) { return benchmarkRegion !== region })
    commit('SET_BENCHMARK_REGIONS', newBenchmarkRegions)
  },
  clearBenchmarkRegions ({ commit }) {
    commit('SET_BENCHMARK_REGIONS', [])
  }
}

export const mutations = {
  SET_PAGE_CONTENT (state, pageContent) {
    state.pageContent = pageContent
  },
  SET_MAP_REGION (state, country) {
    state.mapRegion = country
  },
  SET_BENCHMARK_REGIONS (state, regions) {
    state.benchmarkRegions = regions
  }
}
