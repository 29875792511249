
import { mapGetters } from 'vuex'
import NewsLine from '../components/layout/NewsLine'
import PublicationBlock from '../components/layout/PublicationBlock'
import BaseLayout from '~/layouts/base.vue'
export default {
  components: { BaseLayout, PublicationBlock, NewsLine },
  computed: {
    ...mapGetters({
      pageContent: 'page/pageContent',
      footerContent: 'footerContent',
      marketingContent: 'marketingContent',
      categories: 'categories',
      loggedIn: 'loggedIn',
      publicToken: 'publicToken'
    }),
    marketingText () {
      if (this.pageContent !== null) {
        return this.marketingContent[this.pageContent.category1]
      }
      return this.$i18n.t('home.ged.content')
    }
  }
}
