
export default {
  name: 'EnerSocialFloatingButton',
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    sharedUrl () {
      return 'https://' + this.$i18n.localeProperties.domain + this.$route.fullPath
    },
    facebookLink () {
      return 'https://www.facebook.com/sharer/sharer.php?u=' + this.sharedUrl
    },
    blueskyLink () {
      const encodedUrl = encodeURIComponent(this.sharedUrl)
      const shareText = encodeURIComponent(this.$i18n.t('socialMedia.text'))
      return `https://bsky.app/intent/compose?text=${shareText}+${encodedUrl}`
    },
    LinkedinLink () {
      return 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.sharedUrl
    }
  },
  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen
    },
    handleClickOutside () {
      if (this.isOpen === true) {
        this.isOpen = false
      }
    }
  }
}
