import { capitalize } from '@/utils/StringUtils'

export default class DataVO {
  constructor (apiData, zones, units, i18n) {
    const dataZone = zones.find((zone) => {
      return zone.nrdCode === apiData.zoneCode
    })
    const dataUnit = units.find((unit) => {
      return unit.code === apiData.unitCode
    })
    if (dataZone !== undefined) {
      this.zoneName = dataZone['name' + capitalize(i18n.locale)]
      this.zoneCodeIso2 = dataZone.isoCode2
      this.zoneType = dataZone.type
    } else {
      this.zoneName = apiData.zoneCode
      this.zoneCodeIso2 = apiData.zoneCode
      this.zoneType = 'pays'
    }
    this.zoneCodeNrd = apiData.zoneCode
    this.unitCode = dataUnit.codeEn // TODO get metadata like for countries
    this.year = apiData.year
    this.value = apiData.value
    this.serieCode = apiData.serieCode
    this.scenarioCode = apiData.scenarioCode
    // this.energyCode = getEnergyCode(apiData.serieCode)
    // this.serieShortTitle = getShortTitleForEnergyCode(this.energyCode, i18n)
  }
}
