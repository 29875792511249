import qs from 'qs'
import Bugsnag from '@bugsnag/js'
import customer from '@/api/customer'
import eneroutlook from '@/api/eneroutlook'
import globalServices from '@/api/globalServices'

export default async function (ctx, inject) {
  if (!ctx.store) {
    console.error('Please make sure store module is added')
    return
  }
  if (!ctx.$axios) {
    console.error('Please make sure $axios module is added')
    return
  }
  if (!ctx.$config) {
    console.error('Please make sure $config module is added')
    return
  }

  customer.setAxios(ctx.$axios)
  customer.setUrl(ctx.$config.customerApiUrl)
  if (ctx.store.state.publicToken === null) {
    const publicToken = await customer.getToken(ctx.$config.publicUsername, ctx.$config.publicPassword)
    ctx.store.commit('INIT_PUBLIC_TOKEN', publicToken)
  }

  if (ctx.$cookies.get('user_token') !== undefined) {
    ctx.$axios.setToken(ctx.$cookies.get('user_token'), 'Bearer')
  } else {
    // set token for client side request
    ctx.$axios.setToken(ctx.store.state.publicToken, 'Bearer')
  }
  ctx.$axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'indices', allowDots: true })
  }

  ctx.$axios.interceptors.response.use(
    response => response,
    (error) => {
      const originalRequest = error.config
      if (!process.server && error.response?.status === 401 && !originalRequest.url.endsWith('login_check')) {
        if (ctx.$cookies.get('user_token') !== undefined) {
          ctx.$cookies.remove('user_token', { path: '/' })
        }
        window.location.reload()
      } else {
        Bugsnag.notify(error)
        throw error
      }
    }
  )
  // ctx.$axios.interceptors.request.use((request) => {
  //   console.log('Starting Request', JSON.stringify(request, null, 2))
  //   return request
  // })

  eneroutlook.setAxios(ctx.$axios)
  eneroutlook.setUrl(ctx.$config.eneroutlookApiUrl)
  globalServices.setAxios(ctx.$axios)
  globalServices.setUrl(ctx.$config.globalServicesApiUrl)
  inject('eneroutlook', eneroutlook)
  inject('customer', customer)
}
