import Vue from 'vue'

export default ({ app }) => {
  Vue.filter('formatDate', (value, format) => {
    const date = new Date(value)
    switch (format) {
      case 'DD':
        return date.toLocaleString(['en-US'], { day: '2-digit' })
      case 'MMM':
        return date.toLocaleString(['en-US'], { month: 'short' })
      default:
        return date.toLocaleString(['en-US'], {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
    }
  })

  Vue.filter('formatNumber', (value, nbDecimal) => {
    const number = parseFloat(value)
    return number.toLocaleString(['en-US'], { maximumFractionDigits: nbDecimal })
  })

  Vue.filter('unitName', (value) => {
    const dataUnit = app.store.getters.units.find((unit) => {
      return unit.code === value
    })
    return dataUnit.codeEn
  })
}
