
import { getColorForCategoryName } from '@/utils/colorFunctions'
import { htmlize } from '@/utils/StringUtils'

export default {
  name: 'EnerTopMenu',
  props: {
    items: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      timeout: null,
      clickedCategory: null
    }
  },
  computed: {
    currentCategory () {
      return this.currentPage !== undefined && this.currentPage !== null ? this.currentPage.category1 : null
    },
    openedCategory () {
      if (this.clickedCategory !== null) {
        return this.clickedCategory
      }
      return this.currentCategory
    }
  },
  methods: {
    htmlize (text) {
      return htmlize(text)
    },
    handleMouseLeave () {
      this.timeout = setTimeout(() => {
        this.clickedCategory = null
      }, 2000)
    },
    handleMouseEnter () {
      if (this.timeout !== null) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
    },
    handleClickCategory (item) {
      this.clickedCategory = item.title
    },
    borderStyle (item) {
      const color = getColorForCategoryName(item.icon)
      return 'border-image: linear-gradient(to right, ' + color + '00 0%, ' + color + ' 40%, ' + color + ' 60%, ' + color + '00 100%) 1;'
    }
  }
}
