
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginForm',
  data () {
    return {
      errorMessage: '',
      form: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      gtmUserData: 'gtmUserData'
    })
  },
  methods: {
    ...mapActions({
      updateLoggedIn: 'updateLoggedIn',
      setMessage: 'home/setMessage'
    }),
    async userLogin () {
      this.errorMessage = ''
      try {
        const token = await this.$customer.getToken(this.form.email, this.form.password)
        this.$cookies.set('user_token', token, { path: '/', maxAge: 28800 })
        this.$axios.setToken(token, 'Bearer')
        await this.updateLoggedIn(true)
        this.$gtm.push({
          event: 'login',
          login_type: 'client',
          user_data: this.gtmUserData
        })
        this.$bvModal.hide('login-modal')
        if (this.$nuxt.$route.name.startsWith('register')) {
          const message = {
            type: 'success',
            content: [
              this.$i18n.t('modal.login.redirectMessage')
            ]
          }
          this.setMessage(message)
          await this.$router.push('/')
        }
      } catch (err) {
        this.errorMessage = this.$i18n.t('modal.login.invalidCredential')
      }
    },
    handleForgotPasswordClick () {
      this.$bvModal.hide('login-modal')
      this.$bvModal.show('password-modal')
    }
  }
}
